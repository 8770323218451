<template>
  <div class="grid xs:grid-cols-1 grid-cols-3 sm:grid-cols-3 gap-2 pt-2 pb-6">
    <div
      class="p-2 text-base xs:text-sm whitespace-nowrap"
      :class="color"
      v-if="schedule?.worktime_15"
    >
      <div>{{ schedule?.worktime_15 }}</div>
      <div class="uppercase text-sm">пн–ПТ</div>
    </div>
    <div
      class="p-2 text-base xs:text-sm whitespace-nowrap"
      :class="color"
      v-if="schedule?.worktime_6"
    >
      <div>{{ schedule?.worktime_6 }}</div>
      <div class="uppercase text-sm text-pink-500">СБ</div>
    </div>
    <div
      class="p-2 text-base xs:text-sm whitespace-nowrap"
      :class="color"
      v-if="schedule?.worktime_7"
    >
      <div>{{ schedule?.worktime_7 }}</div>
      <div class="uppercase text-sm text-pink-500">ВС</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    schedule: {
      type: Object,
      default: () => {},
    },

    color: {
      type: String,
      default: "bg-white",
    },
  },
};
</script>

<style lang="scss" scoped>
</style>